import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Button,
  useColorMode,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ImgTextModule from '../components/elements/ImgTextModule';
import { Modal2 } from '../components/ModalDVT';
import Stellenanzeige from '../components/stellenanzeige';
import FadeInAnimation from '../components/UX/FadeInAnimation';
import InViewTrigger from '../components/Utility/UX/InViewTrigger';
import AnimatedCountUpDecimal from '../components/UX/AnimatedCountUpDecimal';
import { AnimatePresence, motion } from 'framer-motion';
import KontaktFooter from '../components/KontaktFooter';
import { LandingStelle } from '../components/UX/LandingStelle';

const LPNordFrost = () => {
  const { setColorMode } = useColorMode();

  const filtered = [
    '6fbd80a6-03cc-494d-ab9a-be934585ae47',
    '8dbfcf56-3ba4-4d9d-aae1-50ea196c6a98',
    '9e4ba28c-d8f1-47e3-99ae-bdc9c4799009',
    '31125693-693f-4aed-ae5a-27d145990e32',
    '5c4f67fd-30c5-4cdb-8330-757f78211dc7',
    'aab86c87-0152-472a-9342-870cd2338ef8',
    '60f78075-f81a-43cb-9d32-96446dc49e27',
    '66773102-56b0-445f-83b5-6e96234ff0ee',
    '0b09c673-e64a-4a15-a421-356399464d55',
    '6ea2cb40-a560-49e1-bd5f-e0f392a26d83',
    'cefa8e93-c3ad-4641-8527-0c098a1bd4e7',
    'b8a9fe75-f4a8-474f-b3b7-9c9fa14cb72a',
    '316b7efb-4141-4ea6-ab15-9a5dfaafc04d',
    '523197cd-4b77-4d52-b7e5-6f11b960d5e7',
    'c7c5dca1-c687-4739-b466-9b507a6463e7',
    '574c999d-d2ce-4b6e-a4e3-b8dbb89545e6',
    'af8e3009-138b-4d78-8719-06f921ea24fd',
    'f4cf5bc7-4f95-4d65-9439-fb898cdb4054',
    '02911ffe-01c6-48f5-9ef4-f3f725589e0f',
    '64b60bf0-8bb1-4fae-8e13-87d60f091ccf',
    '36c7039c-cdde-4a2f-b033-5bc0b6a69122',
    'e1f4537e-cfef-4a45-9b40-80d43475a84b',
    'd4dd4ddf-ba9c-44bc-951a-8877e2cd3745',
  ];
  const filtered2 = [
    '7b440486-2ce7-4f54-9ec2-838caa079012',
    '2a55bbcd-40c5-4d3d-b64b-94a4bf1789ab',
    '10dc9ea0-456e-424b-8c27-31ae992aa3a7',
  ];

  return (
    <>
      <Helmet>
        <title>KDW | Vakanzen Logistik</title>
        <meta
          name='description'
          content='kdw HR bietet Kunden Dienstleistungen von der Personalvermittlung, über qualifizierte Arbeitnehmerüberlassung bis hin zu Management- und Fördermittelberatung im gesamten Personalmanagementbereich. Arbeitnehmer finden bei uns langfristige Karriereoptionen genauso wie neue Herausforderungen vornehmlich im Industrie-, Gewerbe- und Officesegment.'
        />
        <link rel='canonical' href='https:/kdw-hr.de' />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Helmet>

      <ImgTextModule
        src={'LandingpageVideo.mp4'}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h='75vh'
        mediaOpacity={0.8}
        bgColor='brand.gray.100'
      >
        <Container variant='layoutContainer' mt={8}>
          <Box p={{ base: 0, lg: '8' }}>
            <Flex>
              <Box mb={24}>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.4}
                  duration={0.4}
                  initialX={0}
                  initialY={-10}
                >
                  <Text
                    color='white'
                    fontWeight='normal'
                    fontSize={{ base: 'xl', lg: '3xl' }}
                  >
                    Für coole Köpfe
                  </Text>
                  <Text
                    color='brand.green'
                    fontWeight='normal'
                    fontSize={{ base: '4xl', lg: '6xl' }}
                  >
                    Logistik par excellence.
                  </Text>
                </FadeInAnimation>
                <FadeInAnimation
                  threshold={0.4}
                  delay={1}
                  duration={0.7}
                  initialX={0}
                  initialY={10}
                >
                  <Text
                    color='brand.yellow'
                    mb={2}
                    maxW={{ base: '100%', lg: '80%' }}
                    fontSize={{ base: 'xl', lg: '4xl' }}
                    lineHeight={'short'}
                  >
                    für Ihren nächsten Schritt.
                  </Text>
                </FadeInAnimation>
              </Box>
              <InViewTrigger threshold={0.1} triggerOnce={false}>
                {(inView) => (
                  <Box pl={4} ml={4} mt={12} borderLeft='1px solid white'>
                    <AnimatedCountUpDecimal
                      inView={inView}
                      unit='freie Stellen'
                      fontSizeUnit='lg'
                      count={25}
                      fontSize={'6rem'}
                      duration={1}
                      decimal={false}
                      decimalDuration={2}
                      decimalCount={0}
                      unitSpacing={3}
                      numberColor={'white'}
                      textColor={'white'}
                      cellHeight={'6rem'}
                      letterSpacing={'-0.2rem'}
                    ></AnimatedCountUpDecimal>
                  </Box>
                )}
              </InViewTrigger>
            </Flex>
          </Box>
        </Container>
      </ImgTextModule>
      <Container variant='layoutContainer' pt={24}>
        <FadeInAnimation
          threshold={0.4}
          delay={0.5}
          duration={0.7}
          initialX={0}
          initialY={10}
        >
          <Heading
            color='brand.green'
            textAlign={'center'}
            mb={8}
            mx={'auto'}
            maxW={{ base: '100%', lg: '75%' }}
            fontSize={{ base: '4xl', lg: '6xl' }}
            lineHeight={'shorter'}
          >
            Unser Partner ist Deutschlands Marktführer in der Tiefkühllogistik.
          </Heading>
        </FadeInAnimation>
        <FadeInAnimation
          threshold={0.4}
          delay={1}
          duration={0.9}
          initialX={0}
          initialY={10}
        >
          <Heading
            color='brand.gray.700'
            mx={'auto'}
            textAlign={'center'}
            mb={2}
            maxW={{ base: '100%', lg: '50%' }}
            fontSize={{ base: 'xl', lg: 'xl' }}
            lineHeight={'short'}
          >
            Als Familienunternehmen mit zentralem Sitz im norddeutschen
            Schortens bei Wilhelmshaven betreiben wir 40 temperaturgeführte
            Logistikstandorte mit rund 3.000 Beschäftigten und unterhalten ein
            bundesweites und international angebundenes Sammelladungs-Netzwerk
            für Kühlgutsendungen. Unseren Kunden aus der Lebensmittelbranche
            bieten wir ganzheitliche und individuelle Lösungen rund um Lager und
            europaweiten Transport/Feindistribution sowie Hafenlogistik.
          </Heading>
        </FadeInAnimation>
      </Container>
      <Container variant='layoutContainer' py={24}>
        <FadeInAnimation
          threshold={0.4}
          delay={0.5}
          duration={0.7}
          initialX={0}
          initialY={10}
        >
          <Heading
            color='brand.green'
            textAlign={'center'}
            mb={4}
            maxW={{ base: '100%', lg: '100%' }}
            fontSize={{ base: '3xl', lg: '6xl' }}
            lineHeight={'short'}
          >
            Coole Logistik.
          </Heading>
        </FadeInAnimation>

        <FadeInAnimation
          threshold={0.4}
          delay={0.5}
          duration={0.7}
          initialX={0}
          initialY={10}
        >
          <Heading
            color='brand.blue'
            textAlign={'center'}
            mb={4}
            maxW={{ base: '100%', lg: '100%' }}
            fontSize={{ base: 'xl', lg: '3xl' }}
            lineHeight={'short'}
          >
            Innovative Ideen.
          </Heading>
        </FadeInAnimation>
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          mx={'auto'}
          w={{ base: '70%', lg: '50%' }}
          justifyContent={{ base: 'center', lg: 'justify-center' }}
        >
          <InViewTrigger threshold={0.1} triggerOnce={false}>
            {(inView) => (
              <Box pl={4} ml={4} mt={12}>
                <AnimatedCountUpDecimal
                  inView={inView}
                  unit='Standorte'
                  fontSizeUnit='lg'
                  count={40}
                  fontSize={'6rem'}
                  duration={2}
                  decimal={false}
                  decimalDuration={2}
                  decimalCount={0}
                  unitSpacing={3}
                  numberColor={'brand.green'}
                  textColor={'brand.violet'}
                  cellHeight={'6rem'}
                  letterSpacing={'-0.2rem'}
                ></AnimatedCountUpDecimal>
              </Box>
            )}
          </InViewTrigger>
          <InViewTrigger threshold={0.1} triggerOnce={false}>
            {(inView) => (
              <Box
                pl={8}
                ml={4}
                mt={12}
                borderLeft={{ base: 'none', lg: '1px solid' }}
                borderColor={'brand.green'}
              >
                <AnimatedCountUpDecimal
                  inView={inView}
                  unit='Mitarbeitende'
                  fontSizeUnit='lg'
                  count={3000}
                  fontSize={'6rem'}
                  duration={1}
                  decimal={false}
                  decimalDuration={0}
                  decimalCount={0}
                  unitSpacing={3}
                  numberColor={'brand.yellow'}
                  textColor={'brand.violet'}
                  cellHeight={'6rem'}
                  letterSpacing={'-0.2rem'}
                ></AnimatedCountUpDecimal>
              </Box>
            )}
          </InViewTrigger>
        </Flex>
      </Container>
      <Container variant='layoutContainer' py={24}>
        <FadeInAnimation
          threshold={0.4}
          delay={0.5}
          duration={0.7}
          initialX={0}
          initialY={10}
        >
          <Heading
            color='brand.green'
            textAlign={'center'}
            mb={4}
            maxW={{ base: '100%', lg: '100%' }}
            fontSize={{ base: '4xl', lg: '6xl' }}
            lineHeight={'short'}
          >
            Wir suchen aktuell:
          </Heading>
        </FadeInAnimation>
        <FadeInAnimation
          threshold={0.4}
          delay={1}
          duration={0.9}
          initialX={0}
          initialY={10}
        >
          <Heading
            color='brand.yellow'
            textAlign={'center'}
            mb={8}
            maxW={{ base: '100%', lg: '100%' }}
            fontSize={{ base: '2xl', lg: '3xl' }}
            lineHeight={'short'}
          >
            Unsere Vakanzen.
          </Heading>
        </FadeInAnimation>

        {filtered.length > 0 && (
          <Box mx={'auto'} maxW={'4xl'}>
            {filtered.map((user) => (
              <AnimatePresence key={user}>
                <motion.div
                  layout
                  animate={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 10 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    duration: 1,
                    delay: 0,
                  }}
                >
                  <LandingStelle
                    mx={4}
                    sx={{ border: '1px' }}
                    StellenID={user}
                  />
                </motion.div>
              </AnimatePresence>
            ))}
          </Box>
        )}

        {filtered2.length > 0 && (
          <Box mx={'auto'} maxW={'4xl'}>
            {filtered2.map((user) => (
              <AnimatePresence key={user}>
                <motion.div
                  layout
                  animate={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 10 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    duration: 1,
                    delay: 0,
                  }}
                >
                  <LandingStelle
                    mx={4}
                    sx={{ border: '1px' }}
                    StellenID={user}
                    arbeitsOrt={'Beschäftigungsort nach Absprache'}
                  />
                </motion.div>
              </AnimatePresence>
            ))}
          </Box>
        )}
      </Container>
      <KontaktFooter />
    </>
  );
};

export default LPNordFrost;
