import {
  Box,
  Button,
  Stack,
  Spacer,
  Text,
  Heading,
  Flex,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import { motion, AnimatePresence, isValidMotionProp } from "framer-motion";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { chakra } from "@chakra-ui/react";
import FadeInAnimation from './FadeInAnimation';
const ChakraBox1 = chakra(motion.div, {
  shouldForwardProp: isValidMotionProp,
});
export const LandingStelle = ({ StellenID,arbeitsOrt }) => {
  const [stellenDetail, setStellenDetail] = useState([]);
const DTA1 = `https://kdw-partner.europersonal.com/api/public/v1/Stelle/GetStelleById?stelleUuid=${StellenID}&cultureName=de-DE`
  const fetchData1 = () => {
    axios
      .get(
        `https://kdw-partner.europersonal.com/api/public/v1/Stelle/GetStelleById?stelleUuid=${StellenID}&cultureName=de-DE`
      )
      .then((response) => {
        console.log("DATA.FETCH.RESPONSE.COMPLETE");
        console.log(response);
        setStellenDetail(response.data);
        console.log("DATA.FETCH.DETAILS.COMPLETE");
        console.log({ stellenDetail });
        console.log("***********------*******");
      });
  };
  useEffect(() => {

      console.log(arbeitsOrt);

      fetchData1();

  }, []);
  return (
   <Box backgroundColor={'brand.gray.1000'} mb={8} p={8}>
     {/* <Text>{StellenID}</Text>*/}
     {/*<Text> {DTA1}</Text>*/}

              <FadeInAnimation
                threshold={0.4}
                delay={0.22}
                duration={1.0}
                initialX={0}
                initialY={10}>
                <Heading
                  fontSize={{ base: "3xl", lg: "3xl" }}
                  fontWeight="normal"
                  color="brand.violet"
                  mt="4"
                  w={"100%"}
                  letterSpacing="tighter"
                  dangerouslySetInnerHTML={{
                    __html: stellenDetail.Stellenbezeichnung,
                  }}></Heading>
                {!arbeitsOrt &&
                <Heading
                  fontSize={{ base: "xl", lg: "xl" }}
                  fontWeight="normal"
                  color="brand.violet"
                  mt="4"
                  w={"100%"}
                  letterSpacing="tighter"  dangerouslySetInnerHTML={{
                  __html: stellenDetail.EinsatzortOrt,
                }}
                 ></Heading>

                }
                <Heading
                  fontSize={{ base: "xl", lg: "xl" }}
                  fontWeight="normal"
                  color="brand.violet"
                  mt="4"
                  w={"100%"}
                  letterSpacing="tighter"
                >{arbeitsOrt}</Heading>

              </FadeInAnimation>


              <Spacer />
              <FadeInAnimation
                threshold={0.4}
                delay={0.5}
                duration={1.4}
                initialX={0}
                initialY={10}>
                <Flex mt={12} justify="space-between">
                  <Link href={stellenDetail.StelleUrl}>
                    <Button
                      flex={1}
                      bg="brand.violet"
                      px="2rem"
                      fontSize={"sm"}
                      rounded={"full"}
                      color={"white"}
                      boxShadow={
                        "0px 1px 25px -5px rgb(100 100 180 / 48%), 0 10px 10px -5px rgb(100 100  180  / 43%)"
                      }
                      _hover={{
                        bg: "brand.yellow",
                      }}
                      _focus={{
                        bg: "blue.500",
                      }}>
                      zur Bewerbung
                    </Button>
                  </Link>
                </Flex>
              </FadeInAnimation>

   </Box>


  );
};
